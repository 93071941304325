<template>
  <div class="active-imei-config">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Import excel chương trình kích hoạt'">
      <template v-slot:toolbar> </template>
      <template v-slot:preview>
        <div class="mt-4">
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Nhập dữ liệu bằng excel </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <UploadFileMulter
                v-on:send-file="getDataFromExcel"
                v-on:clear-file="clearFileExcel"
                :cmdUrl="'v2/active-configs/import-excel'"
              />
              <span v-if="fileName"
                >Tên file: <b>{{ fileName }}</b>
              </span>
            </b-col>
          </b-row>
          <b-table
            bordered
            hover
            :fields="fields"
            :items="activeConfigs"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(isValid)="row">
              <span
                v-text="getStatusName(row.item)"
                class="label font-weight-bold label-lg label-inline"
                v-bind:class="getStatusClass(row.item)"
              >
              </span>
            </template>
          </b-table>
          <b-pagination
            v-show="activeConfigs.length >= perPage"
            v-model="currentPage"
            :total-rows="activeConfigs.length"
            :per-page="perPage"
            aria-controls="my-table-product"
            align="right"
          ></b-pagination>
        </div>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onSubmit()"
              >Lưu</b-button
            >
            <router-link to="/active-imei-config" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { mapGetters } from 'vuex';
import { BASE_URL } from '@/utils/constants';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatSpecificDate } from '@/utils/date';

export default {
  data() {
    return {
      activeConfigs: [],
      fileName: null,
      isBusy: false,
      urlExcel:
        BASE_URL +
        'media/upload/imei-active-config/Temp_Import_IMEIActiveConfig_.xlsx',
      fields: [
        {
          key: 'name',
          label: 'Tên chương trình',
          thStyle: { width: '30%' },
        },
        {
          key: 'startDate',
          label: 'Thời gian bắt đầu',
          thStyle: { width: '15%' },
        },
        {
          key: 'endDate',
          label: 'Thời gian bắt đầu',
          thStyle: { width: '15%' },
        },
        {
          key: 'isValid',
          label: 'Trạng thái',
          thStyle: { width: '10%' },
        },
        {
          key: 'errorMessages',
          label: 'Thông báo',
          thStyle: { width: '30%' },
        },
      ],
      currentPage: 1,
      perPage: 30,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    UploadFileMulter,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Import chương trình kích hoạt' },
    ]);
  },
  beforeDestroy() {},
  created() {},
  methods: {
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;

      this.activeConfigs = data.rows.map((row) => {
        return {
          ...row,
          errorMessages: row.errorMessages.join(','),
        };
      });
    },
    clearFileExcel() {
      this.activeConfigs = [];
    },
    getStatusName(objItem) {
      if (!objItem.isValid) return 'Lỗi';
      return 'Hợp lệ';
    },
    getStatusClass(objItem) {
      if (!objItem.isValid) return 'label-light-danger';
      return 'label-light-success';
    },
    onSubmit() {
      const inValidDataRow = this.activeConfigs.find(
        (activeConfig) => !activeConfig.isValid,
      );
      if (inValidDataRow) {
        return makeToastFaile('Vui lòng nhập dữ liệu hợp lệ');
      }
      const payload = this.setPayloadActiveConfigs();
      ApiService.post('v2/active-configs/list', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    setPayloadActiveConfigs() {
      return this.activeConfigs.map((activeConfig) => {
        return {
          name: activeConfig.name,
          isActive: 1,
          startDate: activeConfig.startDate
            ? formatSpecificDate(
                activeConfig.startDate,
                'DD/MM/YYYY',
                'yyyy-MM-DD',
              )
            : null,
          endDate: activeConfig.endDate
            ? formatSpecificDate(
                activeConfig.endDate,
                'DD/MM/YYYY',
                'yyyy-MM-DD',
              )
            : null,
          items: activeConfig.items,
          products: activeConfig.products,
          categories: [],
        };
      });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.active-imei-config {
  table {
    table-layout: fixed;
    word-wrap: break-word;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>
